import React from 'react'
import { graphql } from "gatsby"
import tw, { styled, css } from 'twin.macro'
import { createHTML } from '../utils'

import Layout from "../components/Layout"
import Section from '../components/Section'
import ContactUs from '../components/ContactUs'

// This takes on the size of an h2 as per the custom global styles but every page needs to have an h1 for SEO optimization
const TopHeader = styled.h1`
    ${tw`
        text-center font-bold 
        mb-4 mt-36

        text-5xl sm:text-6xl
        leading-tight sm:leading-tight
    `}
`

const Subheader = styled.h4`
    ${tw`
        pt-4
        border-t-4
        text-center font-semibold
    `}

    border-color: darkorange;
`

const AiImage = styled.div`
    ${tw`
        flex 
        flex-grow
        justify-center items-center
    `}

    @media (min-width: 768px) {
        flex-basis: calc(40%);
    }

    > svg, img {
        ${tw`
            max-h-72
        `}
    }
`

const AiText = styled.div`
    ${tw`
        flex 
        flex-grow
        items-center 
        justify-center md:justify-end
        mt-8 md:mt-0
        md:ml-16
    `}

    > h5 {
        ${tw`
            text-2xl lg:text-3xl
            text-center md:text-left
        `}
    }

    flex-basis: 100%;
    
    @media (min-width: 768px) {
        flex-basis: calc(60% - 4rem);
    }
`

const AltenatingItem = styled.div`
    ${tw`
        flex flex-wrap flex-row
        mb-24
        w-full
    `}

    @media (min-width: 768px) {
        grid-column: narrow;

        :nth-child(even) {
            > ${AiImage} {
                ${tw`
                    order-2
                `}
            }

            > ${AiText} {
                ${tw`
                    order-1
                    md:ml-0 md:mr-16
                    justify-start
                `}
            }
        }
    }
`

const AboutUsPage = ({
    data: {
        markdownRemark: {
            frontmatter: { title, subtitle, seo, alternatingData }
        }
    }
}) => {
    return (
        <Layout title={seo.title} description={seo.description}>
            <Section>
                <TopHeader>{title}</TopHeader>
                <Subheader>{subtitle}</Subheader>
            </Section>

            <Section>
                {alternatingData.map(({ text, image, alt }, datumIdx) => (
                    <AltenatingItem>
                        <AiImage>
                            <img src={image.relativePath ? `/img/${image.relativePath}` : image} alt={`Visual representation of ${alt}`} />
                        </AiImage>
                        <AiText>
                            <h5 dangerouslySetInnerHTML={createHTML(text)} />
                        </AiText>
                    </AltenatingItem>
                ))}
            </Section>

            <Section>
                <ContactUs />
            </Section>
        </Layout>
    )
}

export default AboutUsPage

export const pageQuery = graphql`
    query aboutUsQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "about-lt3-page" } }) {
            frontmatter {
                title
                subtitle
                seo {
                    title 
                    description
                }
                alternatingData {
                    text
                    image {
                        relativePath
                    }
                    alt
                }
            }
        }
    }
`